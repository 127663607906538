import styled from 'styled-components';
import Typewriter from 'typewriter-effect';
import { Button, Typography } from '@mui/material';

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @keyframes pop-in {
     0%, 85% { opacity: 0; transform: scale(0.1); } /* Delay for 1 second assuming total animation duration is 5 seconds */
     100% { opacity: 1; transform: scale(1); }
  }

  .connect-button {
      animation:pop-in 1.5s;
  }
`;

function App() {
  return (
    <Centered>
      <Typography variant="h5" sx={{fontWeight: 500, whiteSpace: "pre-line"}}><Typewriter
        options={{
          strings: "I like simple software.\n\nYou too?",
          autoStart: true,
          delay: 20,
        }}
      /></Typography>
      <Button className="connect-button" variant="contained" color="primary" href="https://linkedin.nl/in/simonbaars">Connect With Me</Button>
    </Centered>
  );
}

export default App;